import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './shared/guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./layouts/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./layouts/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./layouts/home/home.module').then((m) => m.HomeModule),
  },
  { path: 'policy', loadChildren: () => import('./layouts/policy/policy.module').then(m => m.PolicyModule) },

  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    useHash: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
